$hellbg: #f2f2f2;
$darkgrey: #444444;
$fontcolor: #5e5e5e;
$bg: rgba(240,240,240,0.95);
$bgblue: rgba(130,161,190, 1);
$bgdark: rgba(82,120,156, 1);
$linkhover:#31567a;
$menuhover: #dbe8f4;
$white: #ffffff;
$black: #000000;
$darkit: rgba(0,0,0,0.1);
$reddit: rgba(180,60,60,0.1);
$whiteit: rgba(240,240,240,0.4);

$headstack: Tahoma,Verdana,Arial,Helvetica, sans-serif;

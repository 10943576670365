/* Layout Stärz */

body {
background: $bgblue;
@include bg-gradient();
margin:0;
padding:0;
font-size:16px;
font-family:Arial,Verdana,Helvetica,sans-serif;
color:$fontcolor;
}

img {
  max-width: 100%;
  height: auto;
}
ul {margin:0; padding:0; line-height:140%;}
li {
margin:5px 0 5px 20px;
padding:0;
list-style-image: url('/img/icons/listicon.gif');
}


#container {
background-color: $white;
@include for_size(tlu) {
  @include shadow-under();
}
width: 100%;
max-width: 940px;
text-align:left;
margin:20px auto;
padding:0;
}

#main_content {
display:block;
margin:0;
padding:5px;
}

#top {
float:none;
display: block;
margin:0;
padding:0;
height:210px;
text-align: center;
border-bottom:  20px solid $bgdark;
@include for_size(tlu) {
  background: $white url('/img/bg/headerimg.jpg') no-repeat top right;
  background-size: contain;
  text-align: left;
  border: none;
  height:180px;
}
}

#logo {
  width: 90%;
  height: auto;
  max-width: 250px;
  margin: 2em auto 0 auto;
@include for_size(tlu) {
  position: relative;
  top: -25px;
  left: 70px;
  height: 150px;
  width: auto;
  max-width: inherit;
  margin: 0;
}
}

#top_bar {
clear:both;
display:block;
padding:0 20px 0 40px;
color:$bgdark;
font-size:0.8em;
position: relative;
@include for_size(tlu) {
top: -20px;
}
}

#top_bar a, #copyright a {
font-weight:normal;
color:$bgdark;
}

#partners a:hover, #copyright a:hover, #wokaweb a:hover {
color:$linkhover;
}

#end {
display:block;
padding:0;
position:relative;
top:0;
left:0;
}

#content {
  padding: 1em;
@include for_size(tlu) {
  width:60%;
  float:left;
  padding:20px 0 20px 40px;
}
line-height:140%;
}

#sidebar_right {
  display: block;
  width: 100%;
  padding-top: 3em;
  clear: both;
@include for_size(tlu) {
  width:30%;
  float:right;
  margin-top:47px;
  clear: none;
  padding: 0;
}
}

#sidebar_right .block h2 {
color:$bgblue;
font-size:0.8em;
border-bottom:1px solid $bgdark;
}

#copyright, #wokaweb {
clear:both;
text-align:center;
display:block;
float:none;
font-size:0.85em;
color:$bgblue;
padding:10px 0px;
margin:10px auto;
border-top:1px solid $bgblue;
}

#wokaweb, #wokaweb a {
font-size:11px;
padding:10px;
text-align:center;
color:$white;
border:none;
}


#menu {
  display: none;
@include for_size(tlu) {
  display:block;
  text-align: center;
  margin:0;
  padding:5px 0;
  background-color: $bgdark;
  border: 2px solid $white;
  border-width: 2px 0;
  @include shadow-under();
}
}

#mobilemenu {
  font-size: 1.2em;
  position: absolute;
  width: 95%;
  display: block;
  text-align: center;
  @include for_size(tlu) {
    display: none !important;
  }
  span {
    display: inline-block;
    white-space: nowrap;
    background-color: $white;
  }
  #toggle {
    float: right;
    cursor: pointer;
    width: 30px;
    height: 30px;
    background: transparent url('../../assets/img/menu.png') no-repeat center center;
  background-size: contain;
    position: relative;
    right: 0;
    display: inline-block;
  }
  ul {
    opacity: 0;
    text-align: left;
    transition: all 0.2s;
    width: 0;
    height: 0;
  }
}

#mobilemenu.show {
  #toggle {
    background-image: url('../../assets/img/close.png');
  }
  ul {
    z-index: 200;
    opacity: 1;
    display: block;
    position: absolute;
    top: 80px;
    left: 0;
    right: 0;
    margin: 0 10px;
    background-color: $bgblue;
    width: 95%;
    height: auto;
    border: 3px solid $white;
    border-width: 3px 0;
    li {
      border-bottom: 1px dashed $white;
      &:last-child {
        border: none;
      }
      a {
        display: block;
        padding: 0.5em 0;
        color: $white;
      }
    }
  }
}

#menu li {
padding:0;
margin:0;
}

#menu li {
padding:0 10px;
display:inline;
border-right:1px solid $white;
}

#menu li.last {border:none;}

#menu li a {
color:$white;
font-family:Arial,Verdana,Helvetica,sans-serif;
font-weight:bold;
}

#menu li a:hover {color:$menuhover;}
#menu li.active {background:url('/img/icons/arrow_w.gif') no-repeat 0px 5px;}

#lightgallery {
  list-style-type: none;
  list-style-image: none;
  padding: 0;
  margin: 0;
@include for_size(tlu) {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
  li {
    display: flex;
    list-style-type: none;
    list-style-image: none;
    padding: 0;
    margin: 0 8px 0 0;
@include for_size(tlu) {
    width: 30%;
}
    img {
      border: 3px double $bgblue;
    }
    a {
      margin: 0 auto;
    }
  }
}

.contact {
  a {
    padding: 0.5em 0.2em;
    margin: 0.2em 0;
    display: inline-block;
    border:1px solid $bgblue;
    background-color: $menuhover;
    border-radius: 5px;
    @include for_size(tlu) {
      border: none;
      background-color: transparent;
      padding: 0.2em 0;
    }
  }
}

@charset "UTF-8";
/* Layout Stärz */
body {
  background: #82a1be;
  background: -moz-linear-gradient(top, #52789c 0%, #82a1be 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #52789c 0%, #82a1be 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #52789c 0%, #82a1be 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: Arial,Verdana,Helvetica,sans-serif;
  color: #5e5e5e;
}

img {
  max-width: 100%;
  height: auto;
}

ul {
  margin: 0;
  padding: 0;
  line-height: 140%;
}

li {
  margin: 5px 0 5px 20px;
  padding: 0;
  list-style-image: url("/img/icons/listicon.gif");
}

#container {
  background-color: #ffffff;
  width: 100%;
  max-width: 940px;
  text-align: left;
  margin: 20px auto;
  padding: 0;
}

@media (min-width: 801px) {
  #container {
    -webkit-box-shadow: 0px 10px 15px -5px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 10px 15px -5px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 10px 15px -5px rgba(0, 0, 0, 0.5);
  }
}

#main_content {
  display: block;
  margin: 0;
  padding: 5px;
}

#top {
  float: none;
  display: block;
  margin: 0;
  padding: 0;
  height: 210px;
  text-align: center;
  border-bottom: 20px solid #52789c;
}

@media (min-width: 801px) {
  #top {
    background: #ffffff url("/img/bg/headerimg.jpg") no-repeat top right;
    background-size: contain;
    text-align: left;
    border: none;
    height: 180px;
  }
}

#logo {
  width: 90%;
  height: auto;
  max-width: 250px;
  margin: 2em auto 0 auto;
}

@media (min-width: 801px) {
  #logo {
    position: relative;
    top: -25px;
    left: 70px;
    height: 150px;
    width: auto;
    max-width: inherit;
    margin: 0;
  }
}

#top_bar {
  clear: both;
  display: block;
  padding: 0 20px 0 40px;
  color: #52789c;
  font-size: 0.8em;
  position: relative;
}

@media (min-width: 801px) {
  #top_bar {
    top: -20px;
  }
}

#top_bar a, #copyright a {
  font-weight: normal;
  color: #52789c;
}

#partners a:hover, #copyright a:hover, #wokaweb a:hover {
  color: #31567a;
}

#end {
  display: block;
  padding: 0;
  position: relative;
  top: 0;
  left: 0;
}

#content {
  padding: 1em;
  line-height: 140%;
}

@media (min-width: 801px) {
  #content {
    width: 60%;
    float: left;
    padding: 20px 0 20px 40px;
  }
}

#sidebar_right {
  display: block;
  width: 100%;
  padding-top: 3em;
  clear: both;
}

@media (min-width: 801px) {
  #sidebar_right {
    width: 30%;
    float: right;
    margin-top: 47px;
    clear: none;
    padding: 0;
  }
}

#sidebar_right .block h2 {
  color: #82a1be;
  font-size: 0.8em;
  border-bottom: 1px solid #52789c;
}

#copyright, #wokaweb {
  clear: both;
  text-align: center;
  display: block;
  float: none;
  font-size: 0.85em;
  color: #82a1be;
  padding: 10px 0px;
  margin: 10px auto;
  border-top: 1px solid #82a1be;
}

#wokaweb, #wokaweb a {
  font-size: 11px;
  padding: 10px;
  text-align: center;
  color: #ffffff;
  border: none;
}

#menu {
  display: none;
}

@media (min-width: 801px) {
  #menu {
    display: block;
    text-align: center;
    margin: 0;
    padding: 5px 0;
    background-color: #52789c;
    border: 2px solid #ffffff;
    border-width: 2px 0;
    -webkit-box-shadow: 0px 10px 15px -5px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 10px 15px -5px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 10px 15px -5px rgba(0, 0, 0, 0.5);
  }
}

#mobilemenu {
  font-size: 1.2em;
  position: absolute;
  width: 95%;
  display: block;
  text-align: center;
}

@media (min-width: 801px) {
  #mobilemenu {
    display: none !important;
  }
}

#mobilemenu span {
  display: inline-block;
  white-space: nowrap;
  background-color: #ffffff;
}

#mobilemenu #toggle {
  float: right;
  cursor: pointer;
  width: 30px;
  height: 30px;
  background: transparent url("../../assets/img/menu.png") no-repeat center center;
  background-size: contain;
  position: relative;
  right: 0;
  display: inline-block;
}

#mobilemenu ul {
  opacity: 0;
  text-align: left;
  transition: all 0.2s;
  width: 0;
  height: 0;
}

#mobilemenu.show #toggle {
  background-image: url("../../assets/img/close.png");
}

#mobilemenu.show ul {
  z-index: 200;
  opacity: 1;
  display: block;
  position: absolute;
  top: 80px;
  left: 0;
  right: 0;
  margin: 0 10px;
  background-color: #82a1be;
  width: 95%;
  height: auto;
  border: 3px solid #ffffff;
  border-width: 3px 0;
}

#mobilemenu.show ul li {
  border-bottom: 1px dashed #ffffff;
}

#mobilemenu.show ul li:last-child {
  border: none;
}

#mobilemenu.show ul li a {
  display: block;
  padding: 0.5em 0;
  color: #ffffff;
}

#menu li {
  padding: 0;
  margin: 0;
}

#menu li {
  padding: 0 10px;
  display: inline;
  border-right: 1px solid #ffffff;
}

#menu li.last {
  border: none;
}

#menu li a {
  color: #ffffff;
  font-family: Arial,Verdana,Helvetica,sans-serif;
  font-weight: bold;
}

#menu li a:hover {
  color: #dbe8f4;
}

#menu li.active {
  background: url("/img/icons/arrow_w.gif") no-repeat 0px 5px;
}

#lightgallery {
  list-style-type: none;
  list-style-image: none;
  padding: 0;
  margin: 0;
}

@media (min-width: 801px) {
  #lightgallery {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
}

#lightgallery li {
  display: flex;
  list-style-type: none;
  list-style-image: none;
  padding: 0;
  margin: 0 8px 0 0;
}

@media (min-width: 801px) {
  #lightgallery li {
    width: 30%;
  }
}

#lightgallery li img {
  border: 3px double #82a1be;
}

#lightgallery li a {
  margin: 0 auto;
}

.contact a {
  padding: 0.5em 0.2em;
  margin: 0.2em 0;
  display: inline-block;
  border: 1px solid #82a1be;
  background-color: #dbe8f4;
  border-radius: 5px;
}

@media (min-width: 801px) {
  .contact a {
    border: none;
    background-color: transparent;
    padding: 0.2em 0;
  }
}

@mixin for-size($size) {
  @if $size == p {
    @media (max-width: 767px) { @content; }
  } @else if $size == tpu {
    @media (min-width: 430px) { @content; }
  } @else if $size == tlu {
    @media (min-width: 801px) { @content; }
  } @else if $size == d {
    @media (min-width: 1200px) { @content; }
  }
}

@mixin shadow-under() {
  -webkit-box-shadow: 0px 10px 15px -5px rgba(0,0,0,0.5);
  -moz-box-shadow: 0px 10px 15px -5px rgba(0,0,0,0.5);
  box-shadow: 0px 10px 15px -5px rgba(0,0,0,0.5);
}

@mixin shadow-top() {
  -webkit-box-shadow: 0px -10px 15px -5px rgba(0,0,0,0.5);
  -moz-box-shadow: 0px -10px 15px -5px rgba(0,0,0,0.5);
  box-shadow: 0px -10px 15px -5px rgba(0,0,0,0.5);
}

@mixin vertical-gradient() {
  background: -moz-linear-gradient(top, rgba(0,0,0,0.85) 0%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0,0,0,0.85) 0%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0,0,0,0.85) 0%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
}

@mixin bg-gradient() {
  background: -moz-linear-gradient(top, $bgdark 0%, $bgblue 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,$bgdark 0%,$bgblue 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, $bgdark 0%,$bgblue 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
